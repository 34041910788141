import React, { createContext, useState, useEffect, useContext } from "react";
import * as Realm from "realm-web";
import { fetchOldSongs } from "./firebaseHelper";

const APP_ID = "xpostersfm-qfeqw";
const MONGODB_NAME = "xpostersfm";

// User credentials for authentication (consider secure storage alternatives)
const userDetails = {
  username: "appuser@null.null",
  password: "QUR*vuz!hwj9qrw.kvq",
};

export const SongsContext = createContext();

export const SongsProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);
  const [xposters, setXposters] = useState([]);
  const [games, setGames] = useState([]);
  const app = new Realm.App({ id: APP_ID });
  const credentials = Realm.Credentials.emailPassword(userDetails.username, userDetails.password);
  const [user, setUser] = useState(null);
  const [atlas, setAtlas] = useState(null);

  // Ensure user is logged in before accessing the database
  useEffect(() => {
    const loginUser = async () => {
      try {
        const loggedInUser = await app.logIn(credentials);
        setUser(loggedInUser);
        const client = loggedInUser.mongoClient("mongodb-atlas");
        setAtlas(client.db(MONGODB_NAME));
      } catch (err) {
        console.error("Error logging in:", err);
      }
    };
    loginUser();
  }, []);

  // Reusable function for database access
  const getCollection = async (collectionName) => {
    if (!atlas) throw new Error("Database client not initialized");
    return atlas.collection(collectionName);
  };

  useEffect(() => {
    if (atlas) {
      fetchSongs();
      fetchXposters();
      fetchGames();
    }
  }, [atlas]); // Depends on atlas being initialized

  const getCollectionItems = async (collection, name) => {
    const colCount = await collection.count();
    const colItems = [];
    const batchSize = 20000;
    const numberOfBatches = Math.ceil(colCount / batchSize);
    for (let i = 0; i < numberOfBatches; i++) {
      const skip = i * batchSize;
      const batch = await collection.find({}, { limit: batchSize, skip: skip });
      colItems.push(...batch);
      console.log(`${name} - Fetched batch ${i + 1} with ${batch.length} items.`);
    }
    return colItems;
  };

  // Fetch data functions
  const fetchSongs = async () => {
    try {
      const oldSongs = await fetchOldSongs();
      setSongs(oldSongs);
      const songsCollection = await getCollection("songs");
      const allSongs = await getCollectionItems(songsCollection, "songs");

      // Combine new songs with old songs
      const combinedSongs = oldSongs ? [...allSongs, ...oldSongs] : allSongs;
      setSongs(combinedSongs);

      console.log("Total Songs fetched:", combinedSongs.length);
    } catch (err) {
      console.error("Error fetching songs:", err);
    }
  };

  const fetchGames = async () => {
    try {
      const gamesCollection = await getCollection("games");
      const records = await getCollectionItems(gamesCollection, "games");
      setGames(records);
    } catch (err) {
      console.error("Error fetching games:", err);
    }
  };

  const fetchXposters = async () => {
    try {
      const xpostersCollection = await getCollection("xposters");
      const records = await getCollectionItems(xpostersCollection, "xposters");
      setXposters(records);
    } catch (err) {
      console.error("Error fetching xposters:", err);
    }
  };

  const getSteamID = async (gameName) => {
    try {
      const steamCollection = await getCollection("steam_game_ids");
      const regex = new RegExp(`^${gameName}$`, "i");
      const record = await steamCollection.findOne({ name: regex });
      return record ? record.appid : null;
    } catch (err) {
      console.error("Error finding Steam ID:", err);
    }
  };

  return (
    <SongsContext.Provider
      value={{
        songs,
        fetchSongs,
        xposters,
        fetchXposters,
        games,
        fetchGames,
        getSteamID,
      }}>
      {children}
    </SongsContext.Provider>
  );
};

// Custom hook for easier context consumption
export const useSongs = () => {
  const context = useContext(SongsContext);
  if (!context) {
    throw new Error("useSongs must be used within a SongsProvider");
  }
  return context;
};
