import React, { useEffect, useRef, useState } from "react";
import { useSongs } from "../lib/atlasHelper";
import UserDropdown from "../components/UserDropdown";
import { Network } from "vis-network";
import { DataSet } from "vis-network/standalone/umd/vis-network";
// import json
import tcolor from "../lib/tailwindColors.json";

function SongGraph() {
  const { songs, xposters } = useSongs();
  const networkRef = useRef(null);
  const networkInstance = useRef(null);

  const [selectableUsers, setSelectableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const selectable = xposters.filter((xposter) => songs.some((song) => song.discordID === xposter.discordID));
    setSelectableUsers(selectable);
  }, [songs, xposters]);

  useEffect(() => {
    if (!selectedUser) return;

    const nodes = new DataSet();
    const edges = new DataSet();

    const userSongsMap = xposters.reduce((acc, xposter) => {
      const userSongs = songs.filter((song) => song.discordID === xposter.discordID).map((song) => song.artist);
      acc[xposter.discordID] = new Set(userSongs);
      return acc;
    }, {});

    // Add the selected user node in the center with a different color
    nodes.add({
      id: selectedUser.discordID,
      label: selectedUser.username,
      x: 0,
      y: 0,
      fixed: true,
      color: { background: "#cbd5e1", strokeWidth: 0 },
      shape: "circle",
      size: 20,
    });

    const otherNodes = [];
    xposters.forEach((xposter, index) => {
      if (xposter.discordID !== selectedUser.discordID) {
        const sharedArtists = calculateSharedArtists(
          userSongsMap[selectedUser.discordID],
          userSongsMap[xposter.discordID]
        );
        if (sharedArtists > 0) {
          // Add the other user nodes to the array to position them later
          otherNodes.push({
            id: xposter.discordID,
            label: xposter.username,
            color: { background: tcolor[index % 9].swatches[4].color, border: "#000000", strokeWidth: 0 }, // Other nodes color
            shape: "circle",
            // size: 20,
            font: { color: tcolor[(index + 5) % 9].swatches[8].color }, // Set font color for the label
          });
          edges.add({
            from: selectedUser.discordID,
            to: xposter.discordID,
            value: sharedArtists,
            label: `${sharedArtists} shared artists`,
            font: { align: "middle", color: tcolor[index % 9].swatches[3].color, strokeWidth: 0 },
            color: tcolor[index % 9].swatches[8].color,
          });
        }
      }
    });

    // Calculate positions for other nodes in a circle around the central node
    const angleStep = (2 * Math.PI) / otherNodes.length;
    otherNodes.forEach((node, index) => {
      const angle = index * angleStep;
      const x = 500 * Math.cos(angle); // Adjust the radius as needed
      const y = 500 * Math.sin(angle);
      node.x = x;
      node.y = y;
      node.fixed = true; // Set fixed to true for a circular layout
      nodes.add(node);
    });

    const data = { nodes, edges };
    const options = {
      edges: {
        width: 2,
        smooth: {
          type: "cubicBezier",
          forceDirection: "horizontal",
          roundness: 0.4,
        },
      },
      physics: false,
    };

    if (networkRef.current) {
      if (networkInstance.current) {
        networkInstance.current.destroy();
      }
      networkInstance.current = new Network(networkRef.current, data, options);
    }

    return () => {
      if (networkInstance.current) {
        networkInstance.current.destroy();
        networkInstance.current = null;
      }
    };
  }, [songs, selectedUser]);

  const calculateSharedArtists = (set1, set2) => {
    const intersection = new Set([...set1].filter((artist) => set2.has(artist)));
    return intersection.size;
  };

  return (
    <div className="full-rmhead flex flex-col">
      <div className="flex items-center justify-center mb-2 mt-2">
        <UserDropdown
          classes=""
          users={selectableUsers}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </div>
      <div ref={networkRef} className="flex-grow" />
    </div>
  );
}

export default SongGraph;
